import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";
import RedirectToNewURL from "./pages/Home/redirect"; // Import the redirect component

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Routes>
        {/* Add the redirection route */}
        <Route path="/imports/coinbase" element={<RedirectToNewURL />} />
        {/* Include the rest of your app's routing */}
        <Route path="/*" element={<Router />} />
      </Routes>
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
