import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectToNewURL = () => {
  const location = useLocation();

  useEffect(() => {
    const queryString = location.search; // Get the query string from the current URL
    const newUrl = `https://app.non-fungibletax.com/imports/coinbase${queryString}`;
    window.location.href = newUrl; // Redirect to the new URL
  }, [location]);

  return null; // No UI is needed for this component
};

export default RedirectToNewURL;